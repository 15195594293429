export default [
	{
		path: '/watchTroubleshooting',
		name: 'watchTroubleshooting',
		meta: {
			title: 'Watch Troubleshooting'
		},
		component: () => import('../../views/faq_view_menu.vue'),
		children: [
			{
				path: 'general-troublesHooting',
				name: 'GeneralTroublesHooting',
				meta: {
					title: 'General TroublesHooting'
				},
				component: () => import('../../views/generalSupport/CantWatchTroublesHooting/GeneralTroublesHooting')
			},
			{
				path: 'LiveTvVodBufferFreezesOrNotWorking',
				name: 'LiveTvVodBufferFreezesOrNotWorking',
				meta: {
					title: 'Live Tv Vod Buffer Freezes Or Not Working'
				},
				component: () => import('../../views/generalSupport/CantWatchTroublesHooting/LiveTvVodBufferFreezesOrNotWorking')
			},
			{
				path: 'TheAppSomethingsStopsOrKicksMeOut',
				name: 'TheAppSomethingsStopsOrKicksMeOut',
				meta: {
					title: 'The App Somethings Stops Or Kicks Me Out'
				},
				component: () => import('../../views/generalSupport/CantWatchTroublesHooting/TheAppSomethingsStopsOrKicksMeOut')
			},
			{
				path: 'IpsExceededLimitCode',
				name: 'IpsExceededLimitCode',
				meta: {
					title: 'Ips Exceeded Limit Code 429'
				},
				component: () => import('../../views/generalSupport/CantWatchTroublesHooting/IpsExceededLimitCode')
			},
			{
				path: 'GetTheMessageBadEmailOrPassword',
				name: 'GetTheMessageBadEmailOrPassword',
				meta: {
					title: 'I Get The Message Bad Email Or Password'
				},
				component: () => import('../../views/generalSupport/CantWatchTroublesHooting/GetTheMessageBadEmailOrPassword')
			},
			{
				path: 'ChangingVodLivetvPlayerType',
				name: 'ChangingVodLivetvPlayerType',
				meta: {
					title: 'Changing VOD / LiveTV Player Type'
				},
				component: () => import('../../views/generalSupport/CantWatchTroublesHooting/ChangingVodLivetvPlayerType')
			},
			{
				path: 'liveTvPlaysButNotMoviesOrTvShows',
				name: 'liveTvPlaysButNotMoviesOrTvShows',
				meta: {
					title: 'Live TV Plays but not Movies or TV shows'
				},
				component: () => import('../../views/generalSupport/CantWatchTroublesHooting/liveTvPlaysButNotMoviesOrTvShows')
			},
			{
				path: 'unauthenticatedError',
				name: 'unauthenticatedError',
				meta: {
					title: 'Unauthenticated Error'
				},
				component: () => import('../../views/generalSupport/CantWatchTroublesHooting/unauthenticatedError')
			},
			{
				path: 'errorCode403',
				name: 'errorCode403',
				meta: {
					title: 'Error Code 403'
				},
				component: () => import('../../views/generalSupport/CantWatchTroublesHooting/errorCode403')
			},
			{
				path: 'chromecastDoesnTWorkProperlyWithYourService',
				name: 'chromecastDoesnTWorkProperlyWithYourService',
				meta: {
					title: "Chromecast Doesn't Work Properly With Your Service"
				},
				component: () => import('../../views/generalSupport/CantWatchTroublesHooting/chromecastDoesnTWorkProperlyWithYourService')
			},







		]
	}
];