<template>
	<div class="flex flex-col" style="height: 100%; width: 100%">
		<HeaderComponent />
		<div style="flex-grow: 1">
			<router-view></router-view>
		</div>

		<footer-component />
	</div>
</template>
<script>
import { mapActions } from 'vuex';

import HeaderComponent from '@/components/HeaderComponent.vue';
import FooterComponent from './components/FooterComponent';
export default {
	name: 'App',
	components: {
		FooterComponent,
		HeaderComponent
	},
	methods: {
		...mapActions(['fetchBrandInfo'])
	},
	created() {
		this.fetchBrandInfo();
	}
};
</script>
