export default [
	{
		path: '/otherAppsM3UPlayers',
		name: 'otherAppsM3UPlayers',
		meta: {
			title: 'Other apps / M3U players'
		},
		component: () => import('../../views/faq_view_menu.vue'),
		children: [
			{
				path: 'whatAreTheM3UEpgUrlsSportsVodUrlIncluded',
				name: 'whatAreTheM3UEpgUrlsSportsVodUrlIncluded',
				meta: {
					title: 'What are the M3U & EPG urls? (Sports VOD URL included)'
				},
				component: () => import('../../views/installationGuides/otherAppsM3UPlayers/whatAreTheM3UEpgUrlsSportsVodUrlIncluded')
			},
			{
				path: 'gseSmartIptv',
				name: 'gseSmartIptv',
				meta: {
					title: 'GSE SMART IPTV'
				},
				component: () => import('../../views/installationGuides/otherAppsM3UPlayers/gseSmartIptv')
			},
			{
				path: 'flexIptv',
				name: 'flexIptv',
				meta: {
					title: 'Flex IPTV'
				},
				component: () => import('../../views/installationGuides/otherAppsM3UPlayers/flexIptv')
			},
			{
				path: 'xCiptvInstallationInstructionMultiScreen',
				name: 'xCiptvInstallationInstructionMultiScreen',
				meta: {
					title: 'XCiptv installation instruction (multi screen)'
				},
				component: () => import('../../views/installationGuides/otherAppsM3UPlayers/xCiptvInstallationInstructionMultiScreen')
			},
			{
				path: 'howDoIInstalGroupTvOnIptvSmarters',
				name: 'howDoIInstalGroupTvOnIptvSmarters',
				meta: {
					title: `How do I install the TV on IPTV Smarters?`
				},
				component: () => import('../../views/installationGuides/otherAppsM3UPlayers/howDoIInstalGroupTvOnIptvSmarters')
			},
			{
				path: 'howDoISetUpWithEmby',
				name: 'howDoISetUpWithEmby',
				meta: {
					title: 'How do I set up with Emby?'
				},
				component: () => import('../../views/installationGuides/otherAppsM3UPlayers/howDoISetUpWithEmby')
			},
			{
				path: 'xtreamCodeNotSupported',
				name: 'xtreamCodeNotSupported',
				meta: {
					title: 'Xtream code (not supported)'
				},
				component: () => import('../../views/installationGuides/otherAppsM3UPlayers/xtreamCodeNotSupported')
			},
			{
				path: 'tvSamsungLgNotSupported',
				name: 'tvSamsungLgNotSupported',
				meta: {
					title: 'TV: SAMSUNG/LG (NOT SUPPORTED)'
				},
				component: () => import('../../views/installationGuides/otherAppsM3UPlayers/tvSamsungLgNotSupported')
			},
			{
				path: 'rokuNotSupported',
				name: 'rokuNotSupported',
				meta: {
					title: 'Roku (not supported)'
				},
				component: () => import('../../views/installationGuides/otherAppsM3UPlayers/rokuNotSupported')
			},
		]
	
	}
];