export default [
	{
		path: '/androidTvBoxFirestick',
		name: 'androidTvBoxFirestick',
		meta: {
			title: 'Android TV Box / Firestick'
		},
		component: () => import('../../views/faq_view_menu.vue'),
		children: [
			{
				path: 'firestickInstallationInstructionsRegularAnd4K',
				name: 'firestickInstallationInstructionsRegularAnd4K',
				meta: {
					title: 'Firestick Installation instructions (regular and 4K)'
				},
				component: () => import('../../views/installationGuides/androidTvBoxFirestick/firestickInstallationInstructionsRegularAnd4K')
			},
			{
				path: 'fireAmazonFireTvFireStickFireTv4K',
				name: 'fireAmazonFireTvFireStickFireTv4K',
				component: () => import('../../views/installationGuides/androidTvBoxFirestick/fireAmazonFireTvFireStickFireTv4K.vue'),
				meta: {
					title: 'Fire-Amazon: FireTV/FireStick/FireTV4k'
				}
			},
			{
				path: 'tivimateInstallationInstructions',
				name: 'tivimateInstallationInstructions',
				component: () => import('../../views/installationGuides/androidTvBoxFirestick/tivimateInstallationInstructions'),
				meta: {
					title: 'Tivimate installation instructions'
				}
			},
			{
				path: 'howDoIInstallOnAndroidBox',
				name: 'howDoIInstallOnAndroidBox',
				meta: {
					title: 'How do I install on Android Box?'
				},
				component: () => import('../../views/installationGuides/androidTvBoxFirestick/howDoIInstallOnAndroidBox')
			},
			{
				path: 'androidBoxGenericBoxes',
				name: 'androidBoxGenericBoxes',
				component: () => import('../../views/installationGuides/androidTvBoxFirestick/androidBoxGenericBoxes'),
				meta: {
					title: 'Android Box: Generic Boxes'
				}
			},
			{
				path: 'manuallyInstallOnAndroidBox',
				name: 'manuallyInstallOnAndroidBox',
				component: () => import('../../views/installationGuides/androidTvBoxFirestick/manuallyInstallOnAndroidBox'),
				meta: {
					title: 'Manually install on Android Box'
				}
			},
			{
				path: 'androidTvNvidiaShieldXiomiMiBoxSonyAndroidTv',
				name: 'androidTvNvidiaShieldXiomiMiBoxSonyAndroidTv',
				component: () => import('../../views/installationGuides/androidTvBoxFirestick/androidTvNvidiaShieldXiomiMiBoxSonyAndroidTv'),
				meta: {
					title: 'Android TV: Nvidia-Shield/Xiomi-MiBox/Sony Android TV'
				}
			},
			{
				path: 'howDoIInstallOnMagBox',
				name: 'howDoIInstallOnMagBox',
				component: () => import('../../views/installationGuides/androidTvBoxFirestick/howDoIInstallOnMagBox'),
				meta: {
					title: 'How do I install on Mag Box ?'
				}
			}
		]
	}
];
