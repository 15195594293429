<template>
	<div>
		<div class="w-full pl-3 flex bg-green-100 justify-center items-center">
			<form class="items-center py-10 w-4/4 text-center">
				<label for="search" class="text-3xl text-bold text-green-500 block pb-3">How can we help you ?</label>
				<input
					v-model="searchKeyword"
					@change="search"
					type="text"
					placeholder="Type Keywords to find answers"
					class="block w-full px-5 py-3 bg-white border border-gray-300 shadow-md drop-shadow-xl text-lg rounded-full text-sm placeholder-gray-400 focus:outline-none focus:border-blue-300 focus:ring-1 focus:ring-sky-500 invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
				/>
				<div class="v-autocomplete-list" v-if="searchFindings.length > 0">
					<div class="v-autocomplete-list-item" v-for="(item, i) in searchFindings" :key="i">
						<router-link :to="{ name: item.routeName }" class="text-gray-500">{{ item.name }}</router-link>
						<hr />
					</div>
				</div>
			</form>
		</div>

		<div class="album py-5 bg-light">
			<div class="container">
				<div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 flex flex-col">
					<div v-for="mainCategory in Object.keys(routerCategories)" :key="mainCategory">
						<h2 class="text-4xl mt-4" :id="mainCategory" style="margin-left: 20px">{{ getHeadline(mainCategory) }}</h2>
						<br />
						<div class="flex flex-wrap">
							<template v-for="(category, index) in categories">
								<div v-if="checkIfInCategory(mainCategory, category)" style="margin: 20px" :key="index">
									<div class="card shadow-sm">
										<div class="bg-white shadow-md text-left p-10" style="width: 350px; height: 300px">
											<h4 class="text-2xl font-normal text-green-400">{{ category.meta.title }}</h4>
											<ul class="pt-1 space-y-1">
												<li v-for="(child, index) in category.children" :key="index">
													<router-link v-if="index === 4" :to="{ name: category.children[0].name }" class="text-gray-500">more...</router-link>
													<router-link v-else-if="index < 4" :to="{ name: child.name }" class="text-gray-500">{{ child.meta.title }}</router-link>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</template>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import _ from 'lodash';
import { routes, routerCategories } from '../router/index.js';

export default {
	name: 'Home',
	computed: {
		categories() {
			return _.filter(routes, (route) => route.children?.length > 0);
		}
	},
	data() {
		return {
			searchKeyword: '',
			searchFindings: [],
			routerCategories
		};
	},
	methods: {
		getHeadline(mainCategory) {
			return _.startCase(mainCategory);
		},
		checkIfInCategory(mainCategory, category) {
			return routerCategories[mainCategory].includes(category.name);
		},
		search() {
			if (this.searchKeyword === '') {
				this.searchFindings = [];
				return;
			}
			const transformedRoutes = _.transform(
				routes,
				(res, key) => res.push(..._.map(key.children, (child) => ({ name: child.meta.title, path: `${key.path}/${child.path}`, routeName: child.name }))),
				[]
			);

			this.searchFindings = _.filter(transformedRoutes, (route) => _.includes(_.toLower(route.path), _.toLower(this.searchKeyword)));
		}
	}
};
</script>

<style>
.v-autocomplete-list {
	background-color: #fff;
	border-bottom-left-radius: 30px;
	border-bottom-right-radius: 30px;
}
.v-autocomplete-list-item {
	padding: 10px;
}
</style>
