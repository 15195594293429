<template>
	<footer>
	
		<div class="bg-gray-900 text-white py-5">
			<div class="max-w-6xl mx-auto flex justify-between">
				<h6 class="text-2xl" style="margin-left: 20px;">
					<small>{{ dateTime }} © {{ $store.state.brandInfo.displayName }}</small>
				</h6>
			</div>
		</div>
	</footer>
</template>

<script>
export default {
	name: 'FooterComponent',
	data() {
		return {
			dateTime: new Date().getFullYear(),
			privacyTerms: 'Privacy & Terms > '
		};
	}
};
</script>
