export default [
	{
		path: '/watchingGroupTV',
		name: 'watchingGroupTv',
		meta: {
			title: 'Watching Group TV'
		},
		component: () => import('../../views/faq_view_menu.vue'),
		children: [
			{
				path: 'howManyDevicesCanBeUsedAtOnCe',
				name: 'HowManyDevicesCanBeUsedAtOnce',
				meta: {
					title: 'How Many Devices Can Be Used At Once?'
				},
				component: () => import('../../views/generalSupport/WatchingGroupTv/HowManyDevicesCanBeUsedAtOnce')
			},
			{
				path: 'WhatHardwareIsRecommended',
				name: 'WhatHardwareIsRecommended',
				meta: {
					title: 'What hardware is recommended?'
				},
				component: () => import('../../views/generalSupport/WatchingGroupTv/WhatHardwareIsRecommended')
			},
			{
				path: 'CanRequestChannelsMovieShows',
				name: 'CanRequestChannelsMovieShows',
				meta: {
					title: 'Can I Request Channels Movie Shows?'
				},
				component: () => import('../../views/generalSupport/WatchingGroupTv/CanRequestChannelsMovieShows')
			},
			{
				path: 'howDoUploadSubtitles',
				name: 'HowDoUploadSubtitles',
				meta: {
					title: 'How I Do Upload Subtitles'
				},
				component: () => import('../../views/generalSupport/WatchingGroupTv/HowDoUploadSubtitles')
			},
			{
				path: 'HowDoChangeTheTimeZone',
				name: 'HowDoChangeTheTimeZone',
				meta: {
					title: 'How I Do Change The TimeZone'
				},
				component: () => import('../../views/generalSupport/WatchingGroupTv/HowDoChangeTheTimeZone')
			},
			{
				path: 'doINeedAVpn',
				name: 'doINeedAVpn',
				meta: {
					title: 'Do I Need A VPN ?'
				},
				component: () => import('../../views/generalSupport/WatchingGroupTv/doINeedAVpn')
			},
			{
				path: 'whyWeDonTSupportIsraeliUsers',
				name: 'whyWeDonTSupportIsraeliUsers',
				meta: {
					title: 'Why We Don’t Support Israeli Users ?!'
				},
				component: () => import('../../views/generalSupport/WatchingGroupTv/whyWeDonTSupportIsraeliUsers')
			}
		]
	}
];
