export default [
	{
		path: '/phoneTabletComputer',
		name: 'phoneTabletComputer',
		meta: {
			title: ' Phone / Tablet / Computer'
		},
		component: () => import('../../views/faq_view_menu.vue'),
		children: [
			{
				path: 'howDoIInstallOnAndroidMobileTablet',
				name: 'howDoIInstallOnAndroidMobileTablet',
				meta: {
					title: 'How do I install on Android Mobile/Tablet?'
				},
				component: () => import('../../views/installationGuides/phoneTabletComputer/howDoIInstallOnAndroidMobileTablet')
			},
			{
				path: 'iOsDevicesIPhoneIPadAppleTvOs13',
				name: 'iOsDevicesIPhoneIPadAppleTvOs13',
				meta: {
					title: 'iOS devices: iPhone/iPad/AppleTV (OS 13+)'
				},
				component: () => import('../../views/installationGuides/phoneTabletComputer/iOsDevicesIPhoneIPadAppleTvOs13')
			},
			{
				path: 'installationInstructionsIPhoneIPad',
				name: 'installationInstructionsIPhoneIPad',
				meta: {
					title: 'Installation instructions iPhone/iPad'
				},
				component: () => import('../../views/installationGuides/phoneTabletComputer/installationInstructionsIPhoneIPad')
			},
			{
				path: 'howDoIAddToKodi',
				name: 'howDoIAddToKodi',
				meta: {
					title: 'how do I add to Kodi?'
				},
				component: () => import('../../views/installationGuides/phoneTabletComputer/howDoIAddToKodi')
			},
			{
				path: 'howDoIInstallOnIPad',
				name: 'howDoIInstallOnIPad',
				meta: {
					title: 'How do I install on iPad?'
				},
				component: () => import('../../views/installationGuides/phoneTabletComputer/howDoIInstallOnIPad')
			},
			{
				path: 'howDoIAddToVlcPlayer',
				name: 'howDoIAddToVlcPlayer',
				meta: {
					title: 'How do I add to VLC Player?'
				},
				component: () => import('../../views/installationGuides/phoneTabletComputer/howDoIAddToVlcPlayer')
			},
			{
				path: 'howDoIWatchOnIMacMacBook',
				name: 'howDoIWatchOnIMacMacBook',
				meta: {
					title: 'How do I watch on iMac & MacBook?'
				},
				component: () => import('../../views/installationGuides/phoneTabletComputer/howDoIWatchOnIMacMacBook')
			},
			// {
			// 	path: 'androidMobileDevices',
			// 	name: 'androidMobileDevices',
			// 	meta: {
			// 		title: 'Android Mobile Devices'
			// 	},
			// 	component: () => import('../../views/installationGuides/phoneTabletComputer/androidMobileDevices')
			// },
			{
				path: 'pcLaptopPcMacbookWebInterface',
				name: 'pcLaptopPcMacbookWebInterface',
				meta: {
					title: 'PC: Laptop/PC/Macbook/Web-interface'
				},
				component: () => import('../../views/installationGuides/phoneTabletComputer/pcLaptopPcMacbookWebInterface')
			}
		]
	}
];
