import superAgent from 'superagent';
import _ from 'lodash';

const defaultApiConfig = { timeout: 20000, headers: { 'Content-Type': 'application/json;charset=UTF-8' } };

const apiRequest = async ({ method, endpoint, query, body, attachments, headers, timeout, binary = false }) => {
	try {
		const httpMethod = _.toLower(method);
		const host = window.location.hostname;
		const apiEndpoint = (process.env.VUE_APP_HTTP_DOMAIN ?? `https://public.${host.replace('faq.', '')}`) + endpoint; // TODO: find solution to not hard code api endpoint

		const request = superAgent[httpMethod](apiEndpoint)
			.set(headers ?? defaultApiConfig.headers)
			// .set('authorization', `Bearer ${VueService.store.state.user?.tokenSet?.access_token}`)
			.timeout(timeout ?? defaultApiConfig.timeout)
			.query(query);

		if (_.size(attachments) > 0) {
			_.each(attachments, (attachment) => request.attach(attachment.name, attachment.file));
			_.each(body, (value, key) => request.field(key, value));
		} else {
			request.send(body);
		}

		if (binary) {
			request.responseType('blob');
			request.buffer(true);
			const response = await request;
			return response;
		}
		return (await request).body.data;
	} catch (error) {
		throw error.status === 503 ? new Error('Failed duo to a problem connecting to the server.') : error;
	}
};

export { apiRequest };
