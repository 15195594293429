document.title = 'Home Page';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import PortalVue from 'portal-vue';
import './assets/tailwind.css';
import './assets/style.css';

Vue.use(PortalVue);
Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: (h) => h(App)
}).$mount('#app');

let defaultTitle = 'Home';

router.beforeEach((to, from, next) => {
	Vue.nextTick(() => {
		document.title = to.meta.title || defaultTitle;
	});
	next();
});
