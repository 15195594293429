export default [
	{
		path: '/account&billing',
		name: 'accountBilling',
		meta: {
			title: 'Account & Billing'
		},
		component: () => import('../../views/faq_view_menu.vue'),
		children: [
			{
				path: 'can-i-get-a-refund',
				name: 'CanIGetRefund',
				meta: {
					title: 'Can i Get a Refund'
				},
				component: () => import('../../views/generalSupport/Account&Billing/CanIGetRefund')
			},
			{
				path: 'do-you-take-recurring-payments',
				name: 'DoYouTakeRecurringPayments',
				component: () => import('../../views/generalSupport/Account&Billing/DoYouTakeRecurringPayments'),
				meta: {
					title: 'Do You Take Recurring Payments'
				}
			},
			{
				path: 'how-do-i-access-my-account-status',
				name: 'HowDoIAccessMyAccountStatus',
				component: () => import('../../views/generalSupport/Account&Billing/HowDoIAccessMyAccountStatus'),
				meta: {
					title: 'How do I access my account status?'
				}
			},
			{
				path: 'how-do-i-renew',
				name: 'HowDoIRenewMySubscription',
				meta: {
					title: 'How Do I Renew My Subscription'
				},
				component: () => import('../../views/generalSupport/Account&Billing/HowDoIRenewMySubscription')
			},
			{
				path: 'resend-or-change-password',
				name: 'RESENDORCHANGEPASSWORD',
				component: () => import('../../views/generalSupport/Account&Billing/RESENDORCHANGEPASSWORD'),
				meta: {
					title: 'Resend Or Change Password'
				}
			}
		]
	}
];
