export default [
	{
		path: '/general',
		name: 'general',
		meta: {
			title: 'General'
		},
		component: () => import('../../views/faq_view_menu.vue'),
		children: [
			{
				path: 'first-steps',
				name: 'FirstSteps',
				meta: {
					title: `First Steps`
				},
				component: () => import('../../views/generalSupport/General/FirstSteps.vue')
			},
			{
				path: 'vpn-information',
				name: 'VPNInformation',
				meta: {
					title: 'VPN Information'
				},
				component: () => import('../../views/generalSupport/General/VPNInformation')
			},
			{
				path: 'free-10day-trial',
				name: 'Free10DayTrial',
				meta: {
					title: 'Free 10 Day Trial'
				},
				component: () => import('../../views/generalSupport/General/Free10DayTrial')
			},
			{
				path: 'how-doisubscribe',
				name: 'HowDoISubscribe',
				meta: {
					title: 'How Do I Subscribe'
				},
				component: () => import('../../views/generalSupport/General/HowDoISubscribe')
			},
			{
				path: 'what-devices-can-i-watch-on-supported-devices',
				name: 'WhatDevicesCanIWatchOnSupportedDevices',
				meta: {
					title: 'What devices can I watch on? (supported devices)'
				},
				component: () => import('../../views/generalSupport/General/WhatDevicesCanIWatchOnSupportedDevices.vue')
			},
			{
				path: 'whatIsThePrice',
				name: 'whatIsThePrice',
				meta: {
					title: `What is the price of the TV ?`
				},
				component: () => import('../../views/generalSupport/General/whatIsThePrice.vue')
			},
			{
				path: 'whatInternetBandwidthSpeedDoINeed',
				name: 'whatInternetBandwidthSpeedDoINeed',
				meta: {
					title: 'What internet bandwidth / speed do I need?'
				},
				component: () => import('../../views/generalSupport/General/whatInternetBandwidthSpeedDoINeed.vue')
			},
			{
				path: 'doYouProvide247ChannelsCatchUp',
				name: 'doYouProvide247ChannelsCatchUp',
				meta: {
					title: 'Do you provide 24/7 Channels (catch up)?'
				},
				component: () => import('../../views/generalSupport/General/doYouProvide247ChannelsCatchUp.vue')
			},
			{
				path: 'doYouHaveAdverts',
				name: 'doYouHaveAdverts',
				meta: {
					title: 'Do you have adverts?'
				},
				component: () => import('../../views/generalSupport/General/doYouHaveAdverts.vue')
			}
		]
	}
];
