import Vue from 'vue';
import _ from 'lodash';
import VueRouter from 'vue-router';
import generalModules from './generalSupportModules';
import installationGuidesModules from './installationGuidesModules';

Vue.use(VueRouter);
const routes = [];

const routerModules = { generalModules, installationGuidesModules };

const categories = {};

_.forEach(routerModules, (routerModule, key) => {
	const moduleKeys = Object.keys(routerModule);

	categories[key] = moduleKeys.map((key) => {
		return _.camelCase(key.split('views_')[1]);
	});

	moduleKeys.forEach((key) => routes.push(...routerModule[key]));
});

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to) {
		if (to.hash) {
			return { selector: to.hash };
		}
		return { x: 0, y: 0 };
	}
});

export { router as default, routes, categories as routerCategories };
