import Vue from 'vue';
import Vuex from 'vuex';
import { apiRequest } from '../helpers/api.js';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		mobileMenuOpen: false,
		brandInfo: {}
	},
	mutations: {
		setBrandInfo(state, brandInfo) {
			state.brandInfo = { ...brandInfo, mainColor: '#521467' };
		},
		SET_USER_SIDEBAR_VISIBLE(state) {
			state.mobileMenuOpen = !state.mobileMenuOpen;
		}
	},
	actions: {
		async fetchBrandInfo({ commit }) {
			const res = await apiRequest({
				method: 'get',
				endpoint: '/api/public/getInfo'
			});
			commit('setBrandInfo', res);
		}
	},
	modules: {}
});
