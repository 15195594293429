import Home from '@/views/Home';

export default [
	{
		path: '/download',
		name: 'Download',
		meta: {
			title: 'Download'
		},
		component: () => import('../../views/Download')
	},
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			title: 'Home'
		}
	}
];
