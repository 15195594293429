<template>
	<header class="py-7 text-white bg-main">
		<nav class="max-w-6xl ml-0">
			<div class="flex font-bold justify-between">
				<div>
					<div class="text-2xl flex">
						<router-link :to="{ name: 'Home' }" class="flex items-center px-2 text-white-700"> <img :src="$store.state.brandInfo.logo" /></router-link>
					</div>
				</div>
				<div class="w-100 flex text-base items-center uppercase">
					<button class="md:hidden text-white-500 w-10 h-10 relative focus:outline-none" @click="open = !open">
						<span class="sr-only">Open main menu</span>
						<div class="block w-5 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
							<span
								aria-hidden="true"
								class="block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out"
								:class="{ 'rotate-45': open, ' -translate-y-1.5': !open }"
							></span>
							<span
								aria-hidden="true"
								class="block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out"
								:class="{ 'opacity-0': open }"
							></span>
							<span
								aria-hidden="true"
								class="block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out"
								:class="{ '-rotate-45': open, ' translate-y-1.5': !open }"
							></span>
						</div>
					</button>

					<router-link :to="{ name: 'Home' }" class="px-8">Home</router-link>
					<router-link :to="{ name: 'Download' }" class="px-8">Download</router-link>
				</div>
			</div>
		</nav>
	</header>
</template>

<script>
export default {
	computed: {
		open: {
			get() {
				return this.$store.state.mobileMenuOpen;
			},
			set() {
				this.$store.commit('SET_USER_SIDEBAR_VISIBLE');
			}
		}
	},
	data() {
		return {};
	}
};
</script>
